<template>
  <div class="home">
    <img src="../../../public/images/contactus/head.jpg" width="100%">
    <div>
      <div class="join_tile">
        关于公司
      </div>
      <p style="text-align:center">您的满意，是我们不懈努力的使命</p>
    </div>
    <div class="contactus_box">
      <div class="contactus_box_1">
        <img src="../../../public/images/contactus/3.jpg" width="100%">
        <div class="div1">公司地址</div>
        <div class="div2">深圳市南山区西丽桃源街道留仙大道4093号南山云谷创新产业园综合服务楼801-810</div>
      </div>
      <div class="contactus_box_2">
        <img src="../../../public/images/contactus/1.jpg" width="100%">
        <div class="div1">联系方式</div>
        <div class="div2">
          <span>联系电话：15817227053</span>
          <p> 业务合作：sales@veepai.com</p>
          <p>工作时间：9:00-12:00；13:30-18:30</p>
        </div>

      </div>
      <div class="contactus_box_3">
        <img src="../../../public/images/contactus/2.jpg" width="100%">
        <div class="div1">微信公众号</div>
        <div class="div2">
          <img src="../../../public/images/contactus/w.png" width="150px" height="150px">
        </div>
      </div>
    </div>
    <div class="contactus_box2">
      <div style=" width: 1220px;font-size: 25px;">如何到达我们公司</div>
      <div class="content1_2">
        <div class="content1_2_right">
          <div>
            <h3>公交车：</h3>
            <p>平山村南 – 公交站/途经公交车：  36路  74路  81路  122路  M217路M299路  M343路  M385路  M393路  M459路</p>
            <p>平山一路路口 – 公交站/途经公交车：  36路  43路</p>
            <p>哈工大(深圳) – 公交站/途经公交车：  37路  M460路</p>
            <p>大学城门 – 公交站/途经公交车：  37路  M217路  M343路  M459路M460路</p>
            <p>哈工大园区 – 公交站/途经公交车：  36路  37路  43路  M460路</p>
            <p>红花岭 – 公交站/途经公交车：  36路  37路  43路  49路  74路  81路104路  122路  M217路  M299路  M343路  M385路M393路  M459路  M554路  高峰专线128路</p>
            <p>丽山路南 – 公交站/途经公交车：  36路  37路  43路  49路  104路</p>
            <p>桃源康复中心 – 公交站/途经公交车：  36路  37路  43路  49路高峰专线128路</p>
            <p>丽水路南 – 公交站/途经公交车：  37路  43路  74路  81路  122路B818路  M459路</p>
            <p>北大园区 – 公交站/途经公交车：  M176路  高峰专线128路</p>
            <h3>地铁：</h3>
            <p>环中线（5号线）大学城站B出口</p>
            <h3>出租车/自驾：</h3>
            <p>地点：南山云谷创新产业园综合服务楼</p>
            <p>地址：深圳市南山区西丽桃源街道留仙大道4093号南山云谷创新产业园综合服务楼801-810</p>
          </div>
        </div>
        <div>
          <img src="../../../public/images/contactus/dt.png" width="600px" height="430px">
        </div>
      </div>
    </div>
    <foot></foot>
    <navigation></navigation>
  </div>
</template>

<script>
import elMenu from '../../../src/components/elMenu'
import foot from '../../../src/components/foot'
import navigation from '../../../src/components/navigation'

export default {
  name: "contactus",
  components: {
    elMenu, foot, navigation
  },
}
</script>

<style scoped>
.join_tile {
  margin-top: 72px;
  text-align: center;
  font-size: 24px;
}

.contactus_box {
  width: 1280px;
  margin: 0px auto;
  height: 420px;
  display: flex;
}

.contactus_box_1 {
  width: 370px;
  height: 370px;
  float: left;
  margin: 2px 25px;
  background: #f9e0e0;
}

.contactus_box_2 {
  width: 370px;
  height: 370px;
  float: left;
  margin: 2px 25px;
  background: #ddf2f4;
}

.contactus_box_3 {
  width: 370px;
  height: 370px;
  float: left;
  margin: 2px 25px;
  text-align: center;
  background: #ebf3da;
}

.div1 {
  font-size: 18px;
  text-align: center;

}

.div2 {
  padding-top: 50px;
  width: 80%;
  margin: auto;
}

.contactus_box2 {
  width: 1280px;
  margin: 0px auto;
  height: 1100px;
}
.content1_2 {
  padding-top: 80px;
  display: flex;
  width: 1360px;
  margin: 0px auto;
  height: 400px;
}

.content1_2_right {
  margin-top: -50px;
  line-height: 30px;
  font-size: 18px;
}

.content1_2_right_top {
  margin-top: 70px;
}
</style>